import { TableauWebComponent } from './Components/TableauWebComponent';
import { PromiseQueue } from './Utils/PromiseQueue';

export class WebComponentManager {
  // This contains a static counter & a map that keeps track of the webComponents embedded.
  private static embeddingIndex = 0;
  private static webComponents: Map<number, TableauWebComponent> = new Map<number, TableauWebComponent>();
  private static queue = new PromiseQueue();

  public static registerWebComponent(webComponent: TableauWebComponent): number {
    const assignedId = this.embeddingIndex;
    this.webComponents.set(assignedId, webComponent);
    this.embeddingIndex++;
    return assignedId;
  }

  public static unregisterWebComponent(embeddingIndex: number): void {
    this.webComponents.delete(embeddingIndex);
  }

  // Methods below only used for testing
  public static getWebComponents(): Map<number, TableauWebComponent> {
    const webComponentsCloned = new Map<number, TableauWebComponent>();
    // a shallow copy is good enough
    this.webComponents.forEach((webComponent: TableauWebComponent, embeddingIndex: number) => {
      webComponentsCloned.set(embeddingIndex, webComponent);
    });
    return webComponentsCloned;
  }

  public static synchronizeRender(func: () => Promise<void>): Promise<void> {
    const promise = this.queue.add(func);
    this.queue.poll();
    return promise;
  }

  public static clear(): void {
    this.webComponents.clear();
  }
}
