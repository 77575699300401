/**
 * This is your main. This is where you re-export everything you want to be publicly available.
 *
 * The build enforces that the file has the same name as the global variable that is exported.
 */

import { EmbeddingErrorCodes, SharedErrorCodes } from '@tableau/api-external-contract-js';
import { ApiVersion } from '@tableau/api-shared-js';
import { TableauAskData } from './EmbeddingApi/Components/TableauAskData';
import { TableauAuthoringViz } from './EmbeddingApi/Components/TableauAuthoringViz';
import { TableauViz } from './EmbeddingApi/Components/TableauViz';

declare let VERSION_IS_ALPHA: boolean;
const isAlpha: boolean = typeof VERSION_IS_ALPHA !== 'undefined' ? VERSION_IS_ALPHA : false;

declare let API_VERSION_NUMBER: string;
ApiVersion.SetVersionNumber(typeof API_VERSION_NUMBER !== 'undefined' ? API_VERSION_NUMBER : '0.0.0', isAlpha);

window.customElements.define('tableau-viz', TableauViz);
window.customElements.define('tableau-authoring-viz', TableauAuthoringViz);
window.customElements.define('tableau-ask-data', TableauAskData);

const ErrorCodes = { ...EmbeddingErrorCodes, ...SharedErrorCodes };
// Export Enums & Interfaces
export {
  AnalyticsObjectType,
  AnnotationType,
  ApiMenuType,
  CategoricalFilter,
  ClassNameKey,
  Column,
  ColumnType,
  CrosstabFileFormat,
  CustomView,
  CustomViewEvent,
  DashboardLayoutChange,
  DashboardObjectType,
  DataSource,
  DataTable,
  DataType,
  DataValue,
  DateRangeType,
  DeviceType,
  EmbeddingDashboard as Dashboard,
  EmbeddingParameter as Parameter,
  EmbeddingParameterChangedEvent as ParameterChangedEvent,
  EmbeddingSheet as Sheet,
  EmbeddingTableauEventType as TableauEventType,
  EmbeddingWorkbook as Workbook,
  EmbeddingWorksheet as Worksheet,
  ExportDataOptions,
  ExportPDFOptions,
  EncodingType,
  FieldAggregationType,
  FieldRoleType,
  Filter,
  FilterChangedEvent,
  FilterDomainType,
  FilterNullOption,
  FilterOptions,
  FilterType,
  FilterUpdateType,
  FirstVizSizeKnownEvent,
  GetSummaryDataOptions,
  HierarchicalFilter,
  HierarchicalFilterDataValue,
  HierarchicalLevelDetail,
  HierarchicalLevels,
  HierarchicalLevelSelectionState,
  IncludeDataValuesOption,
  MarksCollection,
  MarkType,
  ParameterValueType,
  PeriodType,
  PrintOrientation,
  PrintPageSize,
  PrintScaling,
  QuickTableCalcType,
  RangeFilter,
  RelativeDateFilter,
  RelativeDateFilterOptions,
  SelectionCriteria,
  SelectionUpdateType,
  SheetSizeBehavior,
  SheetType,
  Size,
  SortDirection,
  Story,
  StoryPoint,
  StoryPointInfo,
  StoryPointSwitchedEvent,
  TableauDialogType,
  TableauEmbeddingError as TableauError,
  TabSwitchedEvent,
  Toolbar,
  TrendLineModelType,
} from '@tableau/api-external-contract-js';
export { TableauAskData } from './EmbeddingApi/Components/TableauAskData';
export { TableauAuthoringViz } from './EmbeddingApi/Components/TableauAuthoringViz';
export { TableauViz } from './EmbeddingApi/Components/TableauViz';
export { ErrorCodes };
