import * as Contract from '@tableau/api-external-contract-js';
import { AuthoringWorksheetImpl } from '../Impl/AuthoringWorksheetImpl';

export class AuthoringWorksheet implements Contract.EmbeddingAuthoringWorksheet {
  constructor(private _authoringWorksheetImpl: AuthoringWorksheetImpl) {}

  public getAbstractQueryWithDataModelAsync(): Promise<Contract.EmbeddingAbstractQuery> {
    return this._authoringWorksheetImpl.getAbstractQueryWithDataModel();
  }
}
