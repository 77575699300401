import { AskData, AskDataAttributes, AskDataSettings } from '@tableau/api-external-contract-js';
import { createAskDataUrl } from '../Models/EmbeddingAskDataUrl';
import { WebComponentManager } from '../WebComponentManager';
import { TableauAuthResponse, TableauWebComponent } from './TableauWebComponent';

/**
 * Represents the entry point for the `<tableau-ask-data>` custom HTML element.
 * This class is specifically focused on transferring information between the HTML and
 * the AskData, so it should have as little logic as possible.
 */
export class TableauAskData extends TableauWebComponent implements AskData {
  public constructor() {
    super();
  }

  public static get observedAttributes(): string[] {
    // Take caution before adding to this list because for every observed attribute change
    // we unregister and re-render the ask-data webcomponent
    return [...super.observedAttributes, ...Object.values(AskDataAttributes)];
  }

  protected async updateRenderingIfInitialized(src?: string): Promise<void> {
    if (!this._initialized) {
      return;
    }

    WebComponentManager.unregisterWebComponent(this._embeddingIdCounter);
    return this.updateRendering(src);
  }

  protected async updateRendering(src?: string): Promise<void> {
    try {
      this._initialized = true;
      if (!src) {
        console.debug(`A src needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
        return;
      }
      const authResponse = await this.auth();
      if (authResponse === TableauAuthResponse.Failure) {
        console.debug('Authentication failed.');
        return;
      }
      // Nothing to render if the user hasn't provided a src
      if (!this.src) {
        console.debug(`A src needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
        return;
      }
      if (!this.iframe) {
        console.debug('No iframe available to update the src.');
        return;
      }
      const customParams = this.readCustomParamsFromChildren();
      this._embeddingIdCounter = WebComponentManager.registerWebComponent(this);
      const askDataUrl = createAskDataUrl(this.src, this.constructOptions(), this._embeddingIdCounter, customParams).toString();
      this.iframe.src = askDataUrl;
      this.raiseIframeSrcUpdatedNotification();
      return;
    } catch (e) {
      console.warn(e);
    }
  }

  private constructOptions(): AskDataSettings {
    const options: AskDataSettings = {
      origin: this.origin,
      showEmbed: this.showEmbed,
      showPin: this.showPin,
      showSave: this.showSave,
      showShare: this.showShare,
      token: this.token,
      iframeAuth: this.iframeAuth,
      debug: this.debug,
    };
    return options;
  }

  public get origin(): string | undefined {
    const originValue = this.getAttribute(AskDataAttributes.Origin);
    return originValue ? originValue : undefined;
  }

  public set origin(v: string | undefined) {
    if (v) {
      this.setAttribute(AskDataAttributes.Origin, v);
    } else {
      this.removeAttribute(AskDataAttributes.Origin);
    }
  }

  public get showEmbed(): boolean {
    return this.hasAttribute(AskDataAttributes.ShowEmbed);
  }

  public set showEmbed(v: boolean) {
    if (v) {
      this.setAttribute(AskDataAttributes.ShowEmbed, '');
    } else {
      this.removeAttribute(AskDataAttributes.ShowEmbed);
    }
  }

  public get showPin(): boolean {
    return this.hasAttribute(AskDataAttributes.ShowPin);
  }

  public set showPin(v: boolean) {
    if (v) {
      this.setAttribute(AskDataAttributes.ShowPin, '');
    } else {
      this.removeAttribute(AskDataAttributes.ShowPin);
    }
  }

  public get showShare(): boolean {
    return this.hasAttribute(AskDataAttributes.ShowShare);
  }

  public set showShare(v: boolean) {
    if (v) {
      this.setAttribute(AskDataAttributes.ShowShare, '');
    } else {
      this.removeAttribute(AskDataAttributes.ShowShare);
    }
  }

  public get showSave(): boolean {
    return this.hasAttribute(AskDataAttributes.ShowSave);
  }

  public set showSave(v: boolean) {
    if (v) {
      this.setAttribute(AskDataAttributes.ShowSave, '');
    } else {
      this.removeAttribute(AskDataAttributes.ShowSave);
    }
  }
}
