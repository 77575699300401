import { EmbeddingAbstractQuery as ExternalEmbeddingAbstractQuery } from '@tableau/api-external-contract-js';
import {
  EmbeddingAbstractQuery as InternalEmbeddingAbstractQuery,
  ExecuteParameters,
  ParameterId,
  VerbId,
} from '@tableau/api-internal-contract-js';
import { ServiceImplBase } from '@tableau/api-shared-js';
import { EmbeddingAbstractQueryService } from '../EmbeddingAbstractQueryService';
import { EmbeddingServiceNames } from '../EmbeddingServiceNames';

export class EmbeddingAbstractQueryServiceImpl extends ServiceImplBase implements EmbeddingAbstractQueryService {
  public get serviceName(): string {
    return EmbeddingServiceNames.EmbeddingAbstractQuery;
  }

  public getEmbeddingAbstractQueryAsync(): Promise<ExternalEmbeddingAbstractQuery> {
    const verb = VerbId.GetEmbeddingAbstractQueryWithDataModel;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getEmbeddingAbstractQuery',
    };

    return this.execute(verb, parameters).then<InternalEmbeddingAbstractQuery>((response) => {
      const externalAbstractQuery = response.result as ExternalEmbeddingAbstractQuery;
      return externalAbstractQuery;
    });
  }
}
