import { ApiServiceRegistry } from '@tableau/api-shared-js';
import { EmbeddingAbstractQuery } from '@tableau/api-external-contract-js';
import { EmbeddingAbstractQueryService } from '../Services/EmbeddingAbstractQueryService';
import { EmbeddingServiceNames } from '../Services/EmbeddingServiceNames';

export class AuthoringWorksheetImpl {
  constructor(private _registryId: number) {}

  public getAbstractQueryWithDataModel(): Promise<EmbeddingAbstractQuery> {
    const service = ApiServiceRegistry.get(this._registryId).getService<EmbeddingAbstractQueryService>(
      EmbeddingServiceNames.EmbeddingAbstractQuery,
    );
    return service.getEmbeddingAbstractQueryAsync();
  }
}
