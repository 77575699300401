import { InternalApiDispatcher } from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  ClientInfoServiceImpl,
  ServiceRegistry,
  SizeServiceImpl,
  StoryActivationServiceImpl,
} from '@tableau/api-shared-js';
import { InitializationServiceImpl } from './Impl/InitializationServiceImpl';
import { ToolbarServiceImpl } from './Impl/ToolbarServiceImpl';
import { EmbeddingAbstractQueryServiceImpl } from './Impl/EmbeddingAbstractQueryServiceImpl';

export function registerAllEmbeddingServices(dispatcher: InternalApiDispatcher, registryId: number): void {
  const registry: ServiceRegistry = ApiServiceRegistry.get(registryId);

  registry.registerService(new ClientInfoServiceImpl(dispatcher, registryId));
  registry.registerService(new EmbeddingAbstractQueryServiceImpl(dispatcher, registryId));
  registry.registerService(new SizeServiceImpl(dispatcher, registryId));
  registry.registerService(new StoryActivationServiceImpl(dispatcher, registryId));
  registry.registerService(new ToolbarServiceImpl(dispatcher, registryId));
}

export function registerInitializationEmbeddingServices(dispatcher: InternalApiDispatcher, registryId: number): void {
  ApiServiceRegistry.get(registryId).registerService(new InitializationServiceImpl(dispatcher));
}
