// Declare this key type and export the NotificationId to make this behave like a string enum.
//
// PLEASE KEEP IN ALPHABETICAL ORDER. This helps with visually finding entries, knowing where to put
// new ones, and merge conflicts.
export enum VerbId {
  ActivateNextStoryPoint = 'activate-next-story-point',
  ActivatePreviousStoryPoint = 'activate-previous-story-point',
  ActivateSheet = 'activateSheet',
  ActivateStoryPoint = 'activate-story-point',
  AppendExternalMenuItem = 'append-external-menu-item',
  ApplyCategoricalFilter = 'categorical-filter',
  ApplyRangeFilter = 'range-filter',
  ApplyRelativeDateFilter = 'apply-relative-date-Filter',

  BlockExtension = 'block-extension',

  ChangeParameterValue = 'change-parameter-value',
  ChangeSharedFilter = 'change-shared-filter',
  ClearFilter = 'clear-filter',
  ClearSelectedMarks = 'clear-selected-marks',
  CloseDialog = 'close-dialog',
  CreateAnnotation = 'create-annotation',

  DashboardCategoricalFilter = 'dashboard-categorical-filter',
  DisplayDialog = 'display-dialog',
  DownloadWorkbook = 'download-workbook',

  ExecuteExternalMenuItem = 'execute-external-menu-item',
  ExportCrosstab = 'export-crosstab',
  ExportCrosstabCsvDownload = 'export-crosstab-csv-download',
  ExportCrosstabExcelDownload = 'export-crosstab-excel-download',
  ExportData = 'export-data',
  ExportDataDownload = 'export-data-download',
  ExportImage = 'export-image',
  ExportPdf = 'export-pdf',
  ExportPdfDownload = 'export-pdf-download',
  ExportPowerpoint = 'export-powerpoint',
  ExportPowerpointDownload = 'export-powerpoint-download',

  FindParameter = 'find-parameter',

  GetActiveTables = 'get-active-tables',
  GetAllDataSources = 'get-all-data-sources',
  GetAnnotations = 'get-annotations',
  GetCategoricalDomain = 'get-categorical-domain',
  GetConnectionDescriptionSummaries = 'get-connection-description-summaries',
  GetCurrentSrc = 'get-current-src',
  GetCustomViews = 'get-custom-views',
  GetDashboardFilters = 'get-dashboard-filters',
  GetDataSource = 'get-datasource',
  GetDataSourceData = 'get-datasource-data',
  GetDataSources = 'get-data-sources',
  GetDataSummaryData = 'get-summary-data',
  GetDataSummaryDataReader = 'get-summary-data-reader',
  GetDataTableReaderPage = 'get-data-table-reader-page',
  GetEmbeddingAbstractQueryWithDataModel = 'get-embedding-abstract-query-with-data-model',
  GetEmbeddingClientInfo = 'get-embedding-client-info',
  GetExportCrosstabSheetMap = 'get-export-crosstab-sheet-map',
  GetExportPdfOptions = 'get-export-pdf-options',
  GetField = 'get-field',
  GetFieldAndDataSource = 'get-field-and-datasource',
  GetFilters = 'get-filters',
  GetFonts = 'get-fonts',
  GetHighlightedMarks = 'get-highlighted-marks',
  GetJoinDescription = 'get-join-description',
  GetLogicalTableData = 'get-logical-table-data',
  GetLogicalTableDataReader = 'get-logical-table-data-reader',
  GetLogicalTables = 'get-logical-tables',
  GetParametersForSheet = 'get-parameters-for-sheet',
  GetRangeDomain = 'get-range-domain',
  GetSelectedMarks = 'get-selected-marks',
  GetSharedFilter = 'get-shared-filter',
  GetUnderlyingData = 'get-underlying-data',
  GetUnderlyingTableData = 'get-underlying-table-data',
  GetUnderlyingTableDataReader = 'get-underlying-table-data-reader',
  GetUnderlyingTables = 'get-underlying-tables',

  HierarchicalFilter = 'hierachical-filter',

  InitializeExtension = 'initialize-extension',

  MoveAndResizeZones = 'move-and-resize-zones',

  Redo = 'redo',
  RefreshDataSource = 'refresh-data-source',
  ReleaseDataTableReader = 'release-data-table-reader',
  RemoveAnnotation = 'remove-annotation',
  RemoveCustomView = 'remove-custom-view',
  RemoveExternalMenuItem = 'remove-external-menu-item',
  RenameExternalMenu = 'rename-external-menu',
  ReplayAnimation = 'replay-animation',
  RevertStoryPoint = 'revert-story-point',
  RevertWorkbook = 'revert-workbook',

  SaveExtensionSettings = 'save-extension-settings',
  SaveWorkbookAsCustomView = 'save-workbook-as-custom-view',
  SelectByValue = 'select-by-value',
  SetActiveCustomViewAsDefault = 'set-active-custom-view-as-default',
  SetAutoUpdate = 'set-auto-update',
  SetClickThrough = 'set-click-through',
  SetSheetSize = 'set-sheet-size',
  SetZoneVisibility = 'set-zone-visibility',
  Share = 'share',
  ShowCustomView = 'show-custom-view',

  TestConversionVerb = 'test-conversion-verb',

  Undo = 'undo',
  UpdateCustomView = 'update-custom-view',

  VizAPI = 'viz-api',
}
